import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet";
import Layout from '@organisms/Layout'
import netlifyIdentity from 'netlify-identity-widget'
import {Box, Card, Button as RButton, Flex, Text, Heading} from "rebass";


import axios from "axios"

import Button from "@atoms/Button";


const DashBoardPage = () => {
  const [user, setUser] = useState()
  const [mounted, setMounted] = useState(false)
  const [emailsList, setEmailsList] = useState({})

  const onLogin = async (user) => {
    const token = await netlifyIdentity.currentUser().jwt(true)
    const {data} = await axios.post(
      '/.netlify/functions/pulldata',
      {
        email: user.email
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
    netlifyIdentity.close()

    setUser(user)
    setEmailsList(data)
  }

  const onLogOut = () => {
    netlifyIdentity.logout()
    setUser(undefined)
    setEmailsList(undefined)
  }

  useEffect(() => {
    if (!mounted) {
      netlifyIdentity.init()
      netlifyIdentity.on("login", (user) => onLogin(user))
      netlifyIdentity.on("logout", onLogOut);

      setMounted(true)

      return function cleanup() {
        netlifyIdentity.logout()
      }
    }
  }, [mounted, setMounted])

  const csvExport = (list) => {
    console.log('click', list)
    let csv = ''
    emailsList[list].map((item) => csv += item.email + ',' + item.status + '\n')

    const blob = new Blob([csv], { type: 'csv' })
    const a = document.createElement('a')
    a.download = `${list}.csv`
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  return (
    <Layout>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>

      {user && <Box sx={{
        width: '800px',
        maxWidth: '100%',
        mx: 'auto',
        mt:'40px',
        p: 3,
        background: 'white',
        borderRadius: 12,
        boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, 0px 45px 67px rgba(0, 0, 0, 0.12), 0px 22.522px 33.5327px rgba(0, 0, 0, 0.0912399), 0px 13.5662px 20.1985px rgba(0, 0, 0, 0.0781907), 0px 8.69392px 12.9443px rgba(0, 0, 0, 0.0685074), 0px 5.6347px 8.38944px rgba(0, 0, 0, 0.06), 0px 3.54698px 5.28106px rgba(0, 0, 0, 0.0514926), 0px 2.03796px 3.0343px rgba(0, 0, 0, 0.0418093), 0px 0.896959px 1.33547px rgba(0, 0, 0, 0.0287601)',
      }}>
        {emailsList && Object.keys(emailsList).map((list) => {
          if (list === undefined) return null
          return (
            <Card key={list}>
              <Box mb={3}>
                <Heading mb={2}>Subscribers for: {list}</Heading>
                <Text>Total Subscribers: {emailsList[list].length}</Text>
              </Box>
              {emailsList[list].map((item, key) => {
                return (
                  <Flex key={list + '-' + key} flexWrap='wrap' mx={-2}>
                    <Box px={2} py={2} width={1 / 3}>
                      <Text>{item.email}</Text>
                    </Box>
                    <Box px={2} py={2} width={1 / 3}>
                      <Text>
                        <Box
                          sx={{
                            display: 'inline-block',
                            color: 'white',
                            bg: item.status === 'active' ? '#7dbf4c' : '#366dc7',
                            px: 3,
                            py: 1,
                            borderRadius: 9999,
                            textTransform: 'uppercase'
                          }}
                        >{item.status}</Box>
                      </Text>
                    </Box>
                  </Flex>
                )
              })}
              <Box>
                <RButton
                  as="a"
                  onClick={()=>csvExport(list)}
                  sx={{
                    cursor: "pointer",
                  }}
                  mt={2}
                  bg="yellow"
                  variant="primary"
                >
                  Download CSV
                </RButton>
              </Box>
            </Card>
          )
        })}
      </Box>}
      <Box
        sx={{
          width: '800px',
          maxWidth: '100%',
          mx: 'auto',
          p: 3,
          mb: 4,
          mt: user ? '40px' : '25%',
          textAlign: 'center',
          display: 'block'
        }}
      >
        <Button sx={{
          fontSize: 3
        }} onClick={() => {
          netlifyIdentity.open()
        }}>{!user ? 'Login' : 'Logout'}</Button>
      </Box>

    </Layout>
  )
}

export default DashBoardPage
